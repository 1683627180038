body::-webkit-scrollbar {
	width: 5px
}

body::-webkit-scrollbar-button {
	background: #000;
	width: 0;
	height: 0
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	background: #000
}

body::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .5);
	background: #c48f56;
	height: 0
}

#app {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
}

.slides,
canvas {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.slides {
	position: absolute;
	top: 50%;
	padding: 0 10vw;
	max-width: 100%
}

.slide,
.slides {
	box-sizing: border-box;
	width: 100%
}

.slide {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 1200px;
	margin: 0 auto;
	height: 33vh;
	color: #fff;
	transform: translateY(-50%);
	transition: transform 1.3s cubic-bezier(.2, 1, .3, 1), opacity .5s cubic-bezier(.2, 1, .3, 1)
}

.slide-meta {
	margin: 0;
	font-size: 1.35rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.slide-meta,
.slide-more {
	opacity: 0;
	transition: opacity .9s cubic-bezier(.2, 1, .3, 1)
}

.show-meta .slide-meta,
.show-meta .slide-more {
	opacity: 1;
	transition: opacity 1.5s cubic-bezier(.2, 1, .3, 1) .3s
}

.slide-more {
	border-bottom: 1px solid
}

.slide-more:hover {
	border-bottom: 1px solid transparent
}

.slide-title {
	font-size: 10vw;
	margin: 0;
	color: #fafafa;
	text-indent: -.5vw;
	font-family: gibbs, sans-serif;
	font-weight: 900;
	transition: color .5s cubic-bezier(.2, 1, .3, 1)
}

.next,
.prev {
	opacity: 0
}

.prev {
	transform: translateY(-85%)
}

.next {
	transform: translateY(-15%)
}

.next .slide-title,
.prev .slide-title {
	-webkit-text-stroke: 1.5px #fafafa;
	text-stroke: 1.5px #fafafa;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent
}

.scrolling>.slide {
	transform: translateY(-50%);
	opacity: 1
}

body {
	font-size: 18px
}

h2 {
	font-size: 40px;
	color: #fff
}

h2,
h3 {
	margin: 0;
	font-weight: 700
}

h3 {
	font-size: 28px
}

p {
	margin: 0
}

.container {
	max-width: 1170px;
	margin: 0 auto
}

.about-section1 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 10%
}

.about-img {
	width: 48%
}

.about-img img {
	width: 100%
}

.about-content {
	width: 48%
}
.about-content h2 {
    color: #c48f56;
}
.about-content h2>span {
	display: block;
	color: #c48f56
}

.about-content p {
	margin-top: 20px
}

.about-content p>span {
	display: block;
	margin-top: 20px
}

.about-section2 {
	margin-top: 9%;
	text-align: center
}

.specifications-inner {
	display: flex;
	flex-wrap: wrap;
	text-align: left
}

.specifications-card {
	width: calc((100% - 60px)/4);
	background: #c48f56;
	margin-top: 30px;
	color: #fff;
	padding: 20px;
	border-radius: 5px;
	margin-left: 20px
}

.specifications-card:nth-child(4n+1) {
	margin-left: 0
}

.specifications-card .specifications-card p {
	margin-top: 10px
}

.about-section4 {
	margin-top: 9%;
	margin-bottom: 9%;
	text-align: center
}

.work-inner {
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px
}

.work-inner>div {
	width: calc(33.33% - 10px);
	margin-left: 15px;
	margin-top: 15px
}

.work-inner>div:nth-child(3n+1) {
	margin-left: 0
}

.work-inner>div video {
	border-radius: 5px
}

ul.tabs {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	margin: 7% 0 0
}

ul.tabs li {
	margin: 0 7px;
	list-style: none
}

ul.tabs li a {
	color: #c48f56;
	border: 1px solid #c48f56;
	padding: 8px 20px;
	border-radius: 5px;
	transition: .3s ease-in-out
}

ul.tabs li a:hover {
	color: #fff;
	background: #c48f56
}

.services-main {
	text-align: center;
	padding-top: 10%;
	width: 100%;
	object-fit: cover
}

.services-main label {
	color: #c48f56;
	border: 1px solid #c48f56;
	padding: 8px 20px;
	border-radius: 5px;
	transition: .3s ease-in-out;
	margin: 0 7px
}

.services-main input:checked+label {
	color: #fff;
	background: #c48f56
}

.services-main input {
	position: absolute;
	opacity: 0
}

.services-inner {
	display: none;
	flex-wrap: wrap;
	margin: 5% 0 10%;
	width: 100%
}

.services-main input#tab1:checked~#nature,
.services-main input#tab2:checked~#product,
.services-main input#tab3:checked~#people,
.services-main input#tab4:checked~#food,
.services-main input#tab5:checked~#wedding,
.services-main input#tab6:checked~#space {
	display: flex
}

.services-card {
	width: calc((100% - 60px)/4);
	margin-left: 20px;
	margin-top: 20px;
	padding: 12% 0;
	box-sizing: border-box;
	position: relative;
	border-radius: 5px;
	overflow: hidden
}

.services-card:nth-child(4n+1) {
	margin-left: 0
}

.services-card img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover
}

.contact-section {
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center
}

.contact-inner {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	transform: translateY(7%)
}

.contact-form {
	width: 48%
}

.contact-form h3 {
	color: #c48f56
}

.contact-form p {
	margin: 6px 0 20px
}

.contact-form form {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between
}

.contact-form form>div {
	width: 100%;
	margin-bottom: 15px
}

.contact-form form>div:first-child,
.contact-form form>div:nth-child(2) {
	width: calc(50% - 7.5px)
}

.contact-form form input,
.contact-form form textarea {
	outline: none;
	padding: 12px 10px;
	border-radius: 2px;
	width: 100%;
	color: #000;
	border: 1px solid #c48f56
}

.contact-form form .main-btn {
	display: inline-block;
	background: #c48f56;
	color: #fff
}

.contact-details {
	width: 48%
}

.contact-details h3 {
	color: #c48f56
}

.contact-details ul {
	padding: 0;
	margin: 0
}

.contact-details ul li {
	list-style: none;
	margin-top: 22px
}

.contact-details ul li b {
	display: block;
	margin-bottom: 4px
}

.contact-details ul li a {
	color: #c48f56
}

.contact-details ul li img {
	width: auto;
	display: inline-block;
	margin-right: 15px;
	margin-top: 10px;
	filter: invert(1)
}

.text-danger,
.text-success {
	color: #c48f56
}

.modal {
	display: none;
	position: fixed;
	z-index: 1;
	padding-top: 0;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, .6);
	z-index: 99999
}

.modal-content {
	position: relative;
	background-color: #fefefe;
	margin: auto;
	padding: 0;
	max-width: 60%
}

.foodSlides,
.mySlides,
.natureSlides,
.peopleSlides,
.spaceSlides,
.weddingSlides {
	line-height: 0
}

.foodSlides img,
.mySlides img,
.natureSlides img,
.peopleSlides img,
.spaceSlides img,
.weddingSlides img {
	width: 100%
}

.foodSlides img.portrait,
.mySlides img.portrait,
.natureSlides img.portrait,
.peopleSlides img.portrait,
.spaceSlides img.portrait,
.weddingSlides img.portrait {
	width: auto;
	height: 90vh
}

.close {
	top: 10px;
	right: 25px;
	font-size: 35px
}

.close,
.next-btn,
.prev-btn {
	color: #fff;
	position: absolute;
	font-weight: 700
}

.next-btn,
.prev-btn {
	cursor: pointer;
	line-height: 0;
	top: 50%;
	width: auto;
	padding: 16px;
	margin-top: -25px;
	font-size: 20px;
	transition: .6s ease;
	border-radius: 0 3px 3px 0;
	user-select: none;
	-webkit-user-select: none;
	background-color: rgba(0, 0, 0, .8)
}

.next-btn {
	right: 0
}

.next-btn,
.prev-btn {
	border-radius: 3px 0 0 3px
}

.prev-btn {
	transform: rotate(180deg)
}

a.slide-more {
	color: #fff
}

.frame__tip {
	background: #fff;
	display: inline-block!important;
	opacity: 1;
	color: #000;
	border-radius: 5px;
	padding: 9px 0;
	width: 120px!important;
	text-align: center
}

.frame a {
	color: #000
}

body {
	background: #fff
}

body.home-page .frame a {
	color: #fff
}

body.home-page .frame__title-wrap img {
	filter: invert(1)
}

.about-section2 h2,
.about-section4 h2 {
	color: #c48f56
}

.grid {
	background: transparent
}

.grid:after {
	content: "";
	display: block;
	clear: both
}

.grid-item,
.grid-sizer {
	width: 33.333%
}

.grid-item {
	float: left;
	padding: 0 10px
}

.grid-item img {
	display: block;
	max-width: 100%;
}

.s-gallery-grid .grid-item a {
	display: block;
	position: relative
}

.s-gallery-grid .grid-item a:hover:after {
	content: "";
	background: rgba(0, 0, 0, .4);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 8;
	width: 100%
}

.filters {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0 0 40px
}

.filters h3 {
	text-align: center;
	font-size: 35px
}

.filter-button {
	display: inline-block;
	padding: .5em 1em;
	border: none;
	color: #222;
	cursor: pointer;
	background: transparent;
	font-size: 16px;
	font-weight: 400
}

.filter-button:hover {
	color: #222
}

.filter-button.is-checked {
	background: #c48f56;
	color: #fff;
	border-radius: 5px!important;
	    margin: 0em 1em !important;
}

.button-group:after {
	content: "";
	display: block;
	clear: both
}

.button-group .filter-button {
	float: left;
	border-radius: 0;
	margin-left: 0;
	margin-right: 1px;
	outline: none
}

.s-gallery-grid .grid-item a {
	margin-bottom: 20px
}

.share {
	border-bottom: 1px solid #d7d7d7;
	padding: 10px 20px
}

.modal-body a {
	color: #2b2b2b;
	font-size: 16px;
	line-height: 30px
}

body .close {
	color: #000;
	position: absolute;
	top: 0;
	right: 5px;
	font-size: 35px;
	font-weight: 700;
	opacity: 1;
	outline: none
}

.contact-details ul li a[data-toggle=modal] {
	display: inline-block;
	transform: translateY(7px);
	font-weight: 700
}

@media screen and (max-width:1199px) {
	body {
		font-size: 16px
	}
	h2 {
		font-size: 35px;
		margin: 0
	}
	h3 {
		font-size: 26px
	}
	.container {
		max-width: 970px
	}
	.about-content p,
	.about-content p>span {
		margin-top: 10px
	}
}

@media screen and (max-width:991px) {
	body {
		font-size: 14px
	}
	h2 {
		font-size: 30px
	}
	h3 {
		font-size: 24px
	}
	.container {
		max-width: 750px
	}
	.specifications-card {
		width: calc((100% - 20px)/2);
		margin-top: 20px
	}
	.specifications-card:nth-child(4n+1) {
		margin-left: 20px
	}
	.specifications-card:nth-child(odd) {
		margin-left: 0
	}
	.work-inner {
		margin-top: 10px;
		justify-content: space-between
	}
	.work-inner>div {
		width: calc(50% - 7.5px);
		margin-left: 0
	}
	.services-main {
		padding-top: 5%
	}
}

@media screen and (max-width:860px) {
	body.home-page .frame {
    padding: 20px 6%;
    height: 100vh;
}
	.frame__links {
		margin-top: 10px;
		display: block
	}
	p.frame__tip {
		    position: absolute;
    bottom: 20px;
    left: 20px;
	}
}

@media screen and (max-width:767px) {
	body {
		font-size: 12px
	}
	h2 {
		font-size: 28px;
		line-height: 1.2
	}
	h3 {
		font-size: 22px
	}
	.container {
		max-width: 100%;
		padding: 0 6%
	}
	.about-section1.container {
       margin-top: 0; 
    }
	.about-content h2>span {
        display: inline
    };
	.about-img {
		width: 100%
	}
	.about-content {
		width: 100%;
		margin-top: 20px
	}
	.about-content p,
	.about-content p>span {
		margin-top: 10px
	}
	.about-section2 {
		margin-top: 60px
	}
	.specifications-inner {
		margin-top: 20px
	}
	.specifications-card {
		width: 100%;
		margin-top: 10px;
		padding: 15px;
		margin-left: 0
	}
	.specifications-card:nth-child(4n+1) {
		margin-left: 0
	}
	.specifications-card p {
		margin-top: 0
	}
	.work-inner {
		margin-top: 20px
	}
	.work-inner>div {
		width: 100%;
		margin-left: 0;
		margin-top: 10px
	}
	.services-inner {
		justify-content: space-between
	}
	.services-card {
		width: calc(50% - 10px);
		margin-left: 0;
		margin-top: 20px;
		padding: 24% 0
	}
	.services-main label {
		padding: 5px 10px;
		margin: 7px 1px;
		display: inline-block
	}
	.contact-section {
		padding: 0 0 40px;
		box-sizing: border-box;
		min-height: initial;
	}
	.contact-inner {
		transform: translateY(0);
		padding-top: 40px;
	}
	.contact-form {
		width: 100%
	}
	.contact-details {
		width: 100%;
		order: 2;
		text-align: center;
		margin-top: 40px
	}
	.grid-item,
	.grid-sizer {
		/*width: 49%;*/
		padding: 0 5px
	}
	.container.s-gallery-grid {
		padding: 0
	}
	.s-gallery-grid .grid-item a {
		margin-bottom: 10px
	}
	.button-group.filter-button-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 18px
	}
	.filter-button {
		font-size: 14px
	}
	.frame a {
		color: #000;
		font-size: 16px
	}
	.filters {
		padding: 10% 0 0
	}
}


